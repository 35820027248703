var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isProfileLoading())?_c('Splash'):_c('WithSidebar',{scopedSlots:_vm._u([{key:"sidebar-left-header",fn:function(ref){
var toggleSidebar = ref.toggleSidebar;
return [_c('div',{staticClass:"sidebar-header__left"},[_c('img',{staticClass:"avatar-sm",attrs:{"src":_vm.getMyCurrentProfile().avatar}}),_c('h3',[_vm._v(_vm._s(_vm.getMyCurrentProfile().displayName))]),_c('button',{staticClass:"button button-close",on:{"click":toggleSidebar}})])]}},{key:"sidebar-right-header",fn:function(ref){
var toggleSidebar = ref.toggleSidebar;
return [_c('div',{staticClass:"sidebar-header__left"},[_c('img',{staticClass:"avatar-sm",attrs:{"src":_vm.getMyCurrentProfile().avatar}}),_c('h3',[_vm._v(_vm._s(_vm.getMyCurrentProfile().displayName))]),_c('button',{staticClass:"button button-close",on:{"click":toggleSidebar}})])]}},{key:"main",fn:function(ref){
var toggleSidebarLeft = ref.toggleSidebarLeft;
var toggleSidebarRight = ref.toggleSidebarRight;
return [_c('div',{staticClass:"events-container"},[(_vm.$mq === 'sm')?_c('TopNav',{attrs:{"color":"white"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('CurrentProfileBurger',{attrs:{"dark":""},on:{"click":toggleSidebarLeft}})]},proxy:true},{key:"right",fn:function(){return [(!_vm.myNotificationsCount)?_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/exclamation_point.svg")},on:{"click":toggleSidebarRight}}):_vm._e(),_c('NotificationIcon',{on:{"click":toggleSidebarRight}})]},proxy:true}],null,true)}):_vm._e(),_c('nav',{staticClass:"events-container__nav bg-white"},[_c('router-link',{attrs:{"to":{ path: '/events/calendar' },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('Button',{attrs:{"label":"My events","size":"small","primary":isActive,"light":!isActive},on:{"click":navigate}})]}}],null,true)}),_c('router-link',{attrs:{"to":{ path: '/events/requests' },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('Button',{style:({ border: 0 }),attrs:{"label":"Requests","size":"small","primary":isActive,"light":!isActive},on:{"click":navigate}})]}}],null,true)})],1),_c('router-view')],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }