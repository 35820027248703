<template>
    <Splash v-if="isProfileLoading()" />
    <WithSidebar v-else>
        <template #sidebar-left-header="{ toggleSidebar}">
            <div class="sidebar-header__left">
                <img :src="getMyCurrentProfile().avatar" class="avatar-sm" />
                <h3>{{ getMyCurrentProfile().displayName }}</h3>
                <button class="button button-close" @click="toggleSidebar"></button>
            </div>
        </template>
        <template #sidebar-right-header="{ toggleSidebar }">
            <div class="sidebar-header__left">
                <img :src="getMyCurrentProfile().avatar" class="avatar-sm" />
                <h3>{{ getMyCurrentProfile().displayName }}</h3>
                <button class="button button-close" @click="toggleSidebar"></button>
            </div>
        </template>
        <template #main="{ toggleSidebarLeft, toggleSidebarRight }">
            <div class="events-container">
                <TopNav v-if="$mq === 'sm'" color="white">
                    <template #left>
                        <CurrentProfileBurger dark @click="toggleSidebarLeft" />
                    </template>
                    <template #right>
                        <img
                            v-if="!myNotificationsCount"
                            src="@/assets/exclamation_point.svg"
                            class="icon-lg"
                            @click="toggleSidebarRight"
                        />
                        <NotificationIcon @click="toggleSidebarRight" />
                    </template>
                </TopNav>

                <nav class="events-container__nav bg-white">
                    <router-link
                        v-slot="{ isActive, navigate }"
                        :to="{ path: '/events/calendar' }"
                        replace
                    >
                        <Button
                            label="My events"
                            size="small"
                            :primary="isActive"
                            :light="!isActive"
                            @click="navigate"
                        />
                    </router-link>
                    <router-link
                        v-slot="{ isActive, navigate }"
                        :to="{ path: '/events/requests' }"
                        replace
                    >
                        <Button
                            label="Requests"
                            size="small"
                            :primary="isActive"
                            :light="!isActive"
                            :style="{ border: 0 }"
                            @click="navigate"
                        />
                    </router-link>
                </nav>
                <router-view></router-view>
            </div>
        </template>
    </WithSidebar>
</template>

<script>
import TopNav from '@/components/TopNav'
import WithSidebar from '@/components/layout/WithSidebar'
import CurrentProfileBurger from '@/components/CurrentProfileBurger'
import NotificationIcon from '@/components/NotificationIcon'

export default {
    name: 'Events',
    components: {
        NotificationIcon,
        TopNav,
        CurrentProfileBurger,
        WithSidebar
    },
    inject: ['getMyCurrentProfile', 'isProfileLoading'],

    data() {
        return {
            myNotificationsCount: []
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';
.sidebar-header__left {
    height: 90px;
    background: white;
    display: grid;
    padding: 1rem 1rem 0.5rem;
    grid-template-columns: auto 1fr auto;
    column-gap: 1rem;
    align-content: end;
    align-items: center;
}

.events-container {
    height: 100vh;
    width: 100%;
    display: grid;
    align-content: start;
    grid-template-rows: auto auto 1fr;
    &__nav {
        padding: 1rem;
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-content: start;
        column-gap: 1rem;
    }
}
@media only screen and (min-width: 768px) {
    /* For desktop: */
    .events-container {
        height: 100%;
        grid-template-rows: auto 1fr;

        &__nav {
            justify-content: center;
        }
    }
}
</style>
